import storage from "../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const UploadFirebase = async (file) => {
    let imageurl = ""

    const storageRef = ref(storage, `/files/${file.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    await new Promise(resolve => {
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );

                console.log(percent);
            },
            (err) => console.log(err),
            async () => {
                await getDownloadURL(uploadTask.snapshot.ref).then((url) => {

                    imageurl = url;
                    console.log(imageurl);
                    resolve();
                });
            }
        );
    }
    )
    return imageurl;

}